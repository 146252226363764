import { useContext } from 'react';
import { HomeContext } from '../../context/HomeContext';
import SearchBarHeader from '../atom/SearchBarHeader';

function HomeSearchbar() {
  const context = useContext(HomeContext);
  return (
    <SearchBarHeader
      _onKeyUp={(event) => context.setSearch(event.target.value)}
    />
  );
}

export default HomeSearchbar;
