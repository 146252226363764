/* eslint-disable react-hooks/exhaustive-deps */
import axios from '../config/axios';
import { createContext, useEffect, useState } from 'react';
import wslombok from '../data/WS-Lombok.json';
import wssumbawa from '../data/WS-Sumbawa.json';

export const HomeContext = createContext();

function HomeContextProvider({ children }) {
  const [kategori, setKategori] = useState([]);
  const [search, setSearch] = useState('');
  const [das, setDas] = useState(false);
  const [dasLombok, setDasLombok] = useState(null);
  const [dasSumbawa, setDasSumbawa] = useState(null);
  const [infraData, setInfraData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (kategori.length > 0 || search !== '') {
      getDataInfrastruktur();
    } else {
      setInfraData(null);
    }
  }, [kategori, search]);

  const handleOnSetDas = () => {
    if (!das) {
      setIsLoading(true);
      // Promise.all([loadDasLombok(), loadDasSumbawa()]).then((result) => {
      //   const dasLombok = result[0].data;
      //   const dasSumbawa = result[1].data;

      //   setDasLombok(dasLombok);
      //   setDasSumbawa(dasSumbawa);

      //   setIsLoading(false);
      // });
      setDasLombok(wslombok);
      setDasSumbawa(wssumbawa);
      setIsLoading(false);
    } else {
      setDasLombok(null);
      setDasSumbawa(null);
    }

    setDas(!das);
  };

  const handleOnSetKategori = (value) => {
    const arrK = kategori;
    let fIndex = null;
    for (let index = 0; index < arrK.length; index++) {
      if (value === arrK[index]) {
        fIndex = index;
      }
    }

    console.log('findex', fIndex);

    if (fIndex !== null) {
      arrK.splice(fIndex, 1);
      setKategori([...arrK]);
    } else {
      setKategori([...kategori, value]);
    }
  };

  // const loadDasLombok = () => {
  //   return axios.get('http://localhost:3000/WS-Lombok.json');
  //   // return wslombok;
  // };

  // const loadDasSumbawa = () => {
  //   return axios.get('http://localhost:3000/WS-Sumbawa.json');
  //   // return wssumbawa
  // };

  const getDataInfrastruktur = async () => {
    setIsLoading(true);
    try {
      let kt = kategori.toString();
      console.log('kt', kt);
      const res = await axios.get(
        `/api/infrastruktur?search=${search}&kategori=${kt}`
      );
      console.log(res);
      setInfraData(res.data.data.infrastruktur);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const contextValue = {
    kategori,
    dasLombok,
    dasSumbawa,
    infraData,
    setSearch,
    isLoading,
    setIsLoading,
    handleOnSetDas,
    handleOnSetKategori,
  };
  return (
    <HomeContext.Provider value={contextValue}>{children}</HomeContext.Provider>
  );
}

export { HomeContextProvider };
