import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { InfrastrukturContext } from '../../context/InfrastrukturContext';
import ThumbPhoto from '../atom/ThumbPhoto';
import UplaodField from '../atom/UploadField';
import PhotoPreview from './PhotoPreview';

function FormGeneral() {
  const { user } = useContext(AuthContext);
  const {
    infraData,
    handleOnChangePhoto,
    handleOnSetInfraData,
    handleOnSetActiveTab,
  } = useContext(InfrastrukturContext);

  const [preview, setPreview] = useState(false);
  const [activePreview, setActivePreview] = useState(0);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    handleOnSetActiveTab(2);
  };

  if (user.roles === '2') {
    return (
      <div className="p-6">
        <h2 className="font-semibold text-base">Informasi Umum</h2>
        <div className="border rounded-md mt-3">
          <table className="w-full">
            <tbody className="divide-y">
              <tr>
                <td className="font-medium text-sm px-4 py-2">DAS</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.das}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Tahun</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.tahun}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Kondisi</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.kondisi}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">
                  Volume saat ini
                </td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.volume_saat_ini}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">
                  Biaya Konstruksi
                </td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.biaya_konstruksi}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Permasalahan</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.permasalahan}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Keterangan</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.keterangan}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={(e) => handleOnSubmit(e)}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Informasi Umum
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Photo Infrastruktur
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <UplaodField onChange={handleOnChangePhoto} />
                <div className="grid grid-cols-3 gap-3 mt-3">
                  {infraData &&
                    infraData.photo.map((p, index) => {
                      return (
                        <ThumbPhoto
                          key={index}
                          photo={p.path}
                          onClickPreview={() => {
                            setPreview(true);
                            setActivePreview(index);
                          }}
                        />
                      );
                    })}
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Nama Infrastruktur
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      id="nama_infrastruktur"
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="nama_infrastruktur"
                      autoComplete="nama_infrastruktur"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.nama_infrastruktur}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">DAS</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="das"
                      autoComplete="das"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.das}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Kondisi</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="kondisi"
                      autoComplete="kondisi"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.kondisi}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Tahun</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="number"
                      name="tahun"
                      autoComplete="tahun"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.tahun}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Volume saat ini
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="volume_saat_ini"
                      autoComplete="volume_saat_ini"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.volume_saat_ini}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Biaya</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="biaya_konstruksi"
                      autoComplete="biaya_konstruksi"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.biaya_konstruksi}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Permasalahan
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <textarea
                      onChange={(e) => handleOnSetInfraData(e)}
                      name="permasalahan"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.permasalahan}
                    ></textarea>
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Keterangan</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <textarea
                      onChange={(e) => handleOnSetInfraData(e)}
                      name="keterangan"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.keterangan}
                    ></textarea>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Next
          </button>
        </div>
      </form>
      <PhotoPreview
        openPreview={preview}
        setOpenPreview={setPreview}
        activePreview={activePreview}
        photos={infraData.photo}
      />
    </>
  );
}

export default FormGeneral;
