import React from 'react';
import AdminButton from '../atom/AdminButton';
import BackButton from '../atom/BackButton';

function PageHeader(props) {
  return (
    <div className="w-full h-20 bg-gradient-to-b from-gray-300 to-transparent fixed z-30 pr-60 2xl:pr-80">
      <div className="flex h-20 w-full items-center justify-between px-6 pr-6">
        <div className="flex items-center">
          <BackButton />
          <div className="text-blue-900 text-2xl font-medium ml-6 capitalize">
            {props.title}
          </div>
        </div>
        <div className="flex items-center">
          {props.children}
          <AdminButton />
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
