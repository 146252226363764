/* eslint-disable react-hooks/exhaustive-deps */
// import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SearchBarHeader from '../components/atom/SearchBarHeader';
import MapContainerB from '../components/molecul/MapContainerB';
import PageHeader from '../components/molecul/PageHeader';
import wslombok from '../data/WS-Lombok.json';
import wssumbawa from '../data/WS-Sumbawa.json';

function Das() {
  const [dasLombok, setDasLombok] = useState();
  const [dasSumbawa, setDasSumbawa] = useState();

  useEffect(() => {
    // let mounted = true;
    // Promise.all([loadDasLombok(), loadDasSumbawa()]).then((result) => {
    //   const dasLombok = result[0].data;
    //   const dasSumbawa = result[1].data;

    //   if (mounted) {
    //     setDasLombok(dasLombok);
    //     setDasSumbawa(dasSumbawa);
    //   }
    // });

    setDasLombok(wslombok);
    setDasSumbawa(wssumbawa);

    // return () => {
    //   mounted = false;
    // };
  }, []);

  // const loadDasLombok = () => {
  //   return axios.get('http://localhost:3000/WS-Lombok.json');
  // };

  // const loadDasSumbawa = () => {
  //   return axios.get('http://localhost:3000/WS-Sumbawa.json');
  // };

  return (
    <div className="w-full">
      <PageHeader title="Daerah Aliran Sungai">
        <div className="flex items-center mr-6">
          <SearchBarHeader />
          {/* <Link to={`/admin/infrastruktur/2?name=das`}>
            <button className="p-1.5 shadow-lg rounded-full flex items-center justify-center bg-white outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-blue-900"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </button>
          </Link> */}
        </div>
      </PageHeader>
      {/* <MapView dasLombok={dasLombok} dasSumbawa={dasSumbawa} /> */}
      <MapContainerB dasLombok={dasLombok} dasSumbawa={dasSumbawa} />
    </div>
  );
}

export default Das;
