import { useEffect } from 'react';

const useAuth = ({ middleware }) => {
  const logout = () => {
    window.location.pathname = '/login';
  };

  useEffect(() => {
    if (middleware !== 'auth') {
      logout();
    }
  });

  return {
    logout,
  };
};

export default useAuth;
