/* eslint-disable react-hooks/exhaustive-deps */
import axios from '../../config/axios';
import React, { useEffect, useRef, useState } from 'react';
// import ThumbPhoto from './ThumbPhoto';

function UplaodField(props) {
  const uploadInputRef = useRef(null);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    // console.log('effect photo', photos);
    if (photos.length > 0) {
      props.onChange(photos);
    }
  }, [photos]);

  const handleDragOver = (event) => {
    event.preventDefault();
    if (!event.currentTarget.classList.contains('bg-blue-300')) {
      event.currentTarget.classList.remove('bg-gray-100');
      event.currentTarget.classList.add('bg-blue-300');
    }
  };

  const handleDragLeave = (event) => {
    event.currentTarget.classList.add('bg-gray-100');
    event.currentTarget.classList.remove('bg-blue-300');
  };

  const handleDrop = (event) => {
    event.preventDefault();

    // let files = [...event.dataTransfer.files];
    // uploadInputRef.current.files = event.dataTransfer.files;
    // console.log(uploadInputRef.current.files);
    // uploadInputRef.current.onchange();
    // console.log('file', files);
    // let ev = new Event('input', { bubbles: true });
    // uploadInputRef.current.dispatchEvent(ev);

    handleOnChange(event);

    event.currentTarget.classList.add('bg-gray-100');
    event.currentTarget.classList.remove('bg-blue-300');
  };

  const handleOnChange = async (event) => {
    console.log('on change', event.target.files || event.dataTransfer.files);

    let files = event.target.files || event.dataTransfer.files;
    let arrFiles = Array.from(files);
    const upload = arrFiles.map((f) => {
      return uploadFile(f);
    });

    const photos = await Promise.all(upload);
    console.log('photos', photos);
    // setPhotos((prevData) => [...prevData, ...photos]);
    setPhotos([...photos]);
  };

  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const res = await axios.post('/api/infrastruktur/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const photo = res.data.data;
      console.log('response', photo);
      // setPhotos((prevData) => [...prevData, photo]);
      return photo;
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <div className="w-full">
      <div
        onDragOver={(e) => handleDragOver(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        onDrop={(e) => handleDrop(e)}
        className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
      >
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>Upload a file</span>
              <input
                multiple
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                ref={uploadInputRef}
                onChange={(e) => handleOnChange(e)}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        </div>
      </div>
      {/* <div className="grid grid-cols-3 gap-3 mt-3">
        {photos.map((photo, index) => {
          return <ThumbPhoto key={index} photo={photo.path} />;
        })}
      </div> */}
    </div>
  );
}

export default UplaodField;
