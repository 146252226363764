import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { InfrastrukturContext } from '../../context/InfrastrukturContext';

function FormFungsi() {
  const { user } = useContext(AuthContext);
  const { infraData, handleOnSetActiveTab, handleOnSetInfraData } =
    useContext(InfrastrukturContext);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    handleOnSetActiveTab(5);
  };

  if (user.roles === '2') {
    return (
      <div className="p-6">
        <h2 className="font-semibold text-base">Fungsi</h2>
        <div className="border rounded-md mt-3">
          <table className="w-full">
            <tbody className="divide-y">
              <tr>
                <td className="font-medium text-sm px-4 py-2">Irigasi</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.irigasi} Ha
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Ternak</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.ternak} Ekor
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Air Baku</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.air_baku} KK atau lt/dt
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">PLTA</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.plta} KVA
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Lainnya</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.lainnya}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Fungsi Infrastruktur
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Irigasi (Ha)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="irigasi"
                      autoComplete="irigasi"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.irigasi}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Ternak (Ekor)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="ternak"
                      autoComplete="ternak"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.ternak}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Air baku (KK atau lt/dt)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="air_baku"
                      autoComplete="air_baku"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.air_baku}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">PLTM (KVA)</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="plta"
                      autoComplete="plta"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.plta}
                    />
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            onClick={() => handleOnSetActiveTab(3)}
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-1"
          >
            Back
          </button>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
}

export default FormFungsi;
