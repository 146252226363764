import React from 'react';

function Card(props) {
  return (
    <>
      <div className="w-full p-6 bg-white rounded-lg shadow-md">
        <div className="text-lg uppercase font-semibold">{props.title}</div>
        <div className="">{props.jumlah}</div>
      </div>
    </>
  );
}

export default Card;
