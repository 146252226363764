import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AirTanah from '../../pages/AirTanah';
import Bendung from '../../pages/Bendung';
import Bendungan from '../../pages/Bendungan';
import Das from '../../pages/Das';
import Dashboard from '../../pages/Dashboard';
import Embung from '../../pages/Embung';
import Infrastruktur from '../../pages/Infrastruktur';
import MataAir from '../../pages/MataAir';
import Sidebar from '../molecul/Sidebar';

function DefaultTemplate() {
  let { path } = useRouteMatch();
  return (
    <div className="w-full min-h-screen flex">
      <div className="w-56 2xl:w-80 h-screen relative">
        <Sidebar />
      </div>
      <div className="flex-1 w-full min-h-screen">
        <Switch>
          <Route exact path={`${path}/dashboard`}>
            <Dashboard />
          </Route>
          <Route path={`${path}/das`}>
            <Das />
          </Route>
          <Route path={`${path}/bendungan`}>
            <Bendungan />
          </Route>
          <Route path={`${path}/embung`}>
            <Embung />
          </Route>
          <Route path={`${path}/bendung`}>
            <Bendung />
          </Route>
          <Route path={`${path}/mata-air`}>
            <MataAir />
          </Route>
          <Route path={`${path}/air-tanah`}>
            <AirTanah />
          </Route>
          <Route path={`${path}/infrastruktur/:id`}>
            <Infrastruktur />
          </Route>
          <Route path={`${path}/infrastruktur/:id`}>
            <Infrastruktur />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default DefaultTemplate;
