import { Link } from 'react-router-dom';

const PopupCard = (props) => {
  // console.log(JSON.parse(props.kabupaten).name);
  return (
    <div>
      <div className="w-full h-full flex -ml-1">
        <div>
          <div className="h-full">
            <div className="w-24 h-24 bg-gray-300 rounded-md overflow-hidden">
              <img
                className="w-full h-full object-cover"
                src={
                  props.photo
                    ? props.photo
                    : 'https://www.btklsby.go.id/images/placeholder/basic.png'
                }
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="flex-1 h-full">
          <div className="flex flex-col justify-between px-4 h-24">
            <div>
              <div className="text-base font-medium">{props.name}</div>
              <div className="text-xs text-gray-500 font-normal mt-0.5">
                {JSON.parse(props.kabupaten).name}
              </div>
            </div>
            <div className="mt-0.5">
              <button
                type="button"
                className="font-medium outline-none mr-3"
                onClick={props.onClickDetail}
              >
                Detail
              </button>
              <Link
                to={`/admin/infrastruktur/${props.id}?action=edit&name=${props.kategori_name}`}
                className="font-medium"
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupCard;
