import axios from '../../config/axios';
import React, { useContext } from 'react';
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
  Marker,
  Popup,
} from 'react-leaflet';
import PopupCard from './PopupCard';
import { MapContainerContext } from '../../context/MapContainerContext';
import MarkerCustom from './MarkerCustom';

function MapViewB(props) {
  const { toogleSliderOver, setToogleSliderOver, setInfrastruktur } =
    useContext(MapContainerContext);
  const position = [-8.591341, 117.361826];
  const strokeStyle = {
    weight: 1,
    fillOpacity: 0.05,
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.Name) {
      layer.bindPopup(feature.properties.Name);
    }

    // layer.on('click', () => {
    //   console.log('click', feature.properties);
    // });
  };

  const onEachFeatureDasSumbawa = (feature, layer) => {
    if (feature.properties && feature.properties.description) {
      layer.bindPopup(extrackData(feature.properties.description));
    }

    // layer.on('click', () => {
    //   console.log('click', feature.properties);
    // });
  };

  const extrackData = (dataString) => {
    let s = dataString.search('NAMA_DAS');
    // console.log('s', s);
    // let found = true;
    let word = '';
    let wordFound = '';
    let it = 0;
    for (let index = s; index < s + 50; index++) {
      const element = dataString[index];
      if (element === '>') {
        it++;
      }

      if (it >= 2) {
        word += element;
      }

      if (it === 3) {
        // console.log('word found', word);
        break;
      }
    }

    wordFound = word.substring(1);
    wordFound = wordFound.replace('</td>', '');
    return wordFound;
  };

  const handleClickMap = (e) => {
    console.log(e);
  };

  const handleOnclikDetail = (toogle, data) => {
    setToogleSliderOver(true);
    setInfrastruktur(data);
  };

  return (
    <div className="w-full relative z-10">
      <MapContainer
        center={position}
        zoom={9}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{ width: '100%', height: '100vh', zIndex: 5 }}
        onClick={handleClickMap}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.mapbox.com/styles/v1/alwan27/cktmlpfbh15kc18s8ofjqm53u/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWx3YW4yNyIsImEiOiJja21lcm9zbWkwemI1MnVuOGd4NzBzdmdmIn0.zH0KG2I5pGNjDXbhRhZEkg"
        />

        {props.infraData &&
          props.infraData.map((data, i) => (
            <Marker
              key={i}
              position={[parseFloat(data.lat), parseFloat(data.lng)]}
              icon={MarkerCustom(32, data.kategori.id)}
            >
              <Popup style={{ paddingLeft: '-15px' }}>
                <PopupCard
                  id={data.id}
                  kategori_id={data.kategori.id}
                  kategori_name={data.kategori.nama}
                  photo={
                    data.photo.length > 0
                      ? axios.defaults.baseURL + data.photo[0].path
                      : null
                  }
                  name={data.nama_infrastruktur}
                  kabupaten={data.kabupaten}
                  onClickDetail={() =>
                    handleOnclikDetail(toogleSliderOver, data)
                  }
                />
              </Popup>
            </Marker>
          ))}

        {props.dasLombok && (
          <GeoJSON
            key="key-das-lombok"
            data={props.dasLombok}
            style={strokeStyle}
            onEachFeature={onEachFeature}
          />
        )}
        {props.dasSumbawa && (
          <GeoJSON
            key="key-das-sumbawa"
            data={props.dasSumbawa}
            style={strokeStyle}
            onEachFeature={onEachFeatureDasSumbawa}
          />
        )}

        <ZoomControl position="bottomleft" />
      </MapContainer>
    </div>
  );
}

export default MapViewB;
