import React from 'react';
import { Link } from 'react-router-dom';
import SearchBarHeader from '../components/atom/SearchBarHeader';
import MapContainerB from '../components/molecul/MapContainerB';
import PageHeader from '../components/molecul/PageHeader';
import useFetch from '../hooks/useFetch';

function MataAir() {
  const kategoriId = 5;
  const kategoriName = 'mata air';
  const { data } = useFetch(`/api/infrastruktur?kategori=${kategoriId}`);

  return (
    <div className="w-full relative">
      <PageHeader title="Mata Air">
        <div className="flex items-center mr-6">
          <SearchBarHeader />
          <Link
            to={`/admin/infrastruktur/${kategoriId}?action=add&name=${kategoriName}`}
          >
            <button className="p-1.5 shadow-lg rounded-full flex items-center justify-center bg-white outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-blue-900"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </button>
          </Link>
        </div>
      </PageHeader>
      {data && <MapContainerB infraData={data.infrastruktur} />}
    </div>
  );
}

export default MataAir;
