/* eslint-disable react-hooks/exhaustive-deps */
import jwtDecode from 'jwt-decode';
import { createContext, useEffect, useState } from 'react';
import { setAuthorizationHeader } from '../config/axios';
import AuthService from '../services/auth-service';

export const AuthContext = createContext();

function AuthProvider(props) {
  let timeOutFn;
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(async () => {
    console.log('use effect AuthContext refresh');
    await checkToken();

    return () => {
      console.log('unmount');
      // clearInterval(intervalFn);
      clearTimeout(timeOutFn);
    };
  }, []);

  const checkToken = async () => {
    if (localStorage.getItem('auth.infrastruktur.token')) {
      await getUser();
    } else {
      setAuth(false);
    }
  };

  const login = async (credentials) => {
    try {
      await AuthService.login(credentials);
      await getUser();
    } catch (error) {
      console.error('error', error);
      return Promise.reject(error);
    }
  };

  const logout = () => {
    AuthService.logout();
    setUser(null);
    setAuth(false);
    // clearInterval(intervalFn);
    clearTimeout(timeOutFn);
  };

  const getUser = async () => {
    try {
      const token = localStorage.getItem('auth.infrastruktur.token');
      setAuthorizationHeader(token);
      const res = await AuthService.me();

      setUser(res.data.data.user);
      setAuth(true);
      // refreshTokenInterval();
      refreshTokenTimeOut();
    } catch (error) {
      localStorage.removeItem('token');
      // setAuth(false);
      logout();
    }
  };

  const refreshToken = async () => {
    try {
      const token = localStorage.getItem('auth.infrastruktur.token');
      if (token != null) {
        await AuthService.freshToken();
        // refreshTokenInterval();
        refreshTokenTimeOut();
      }
    } catch (error) {
      // clearInterval(intervalFn);
      clearTimeout(timeOutFn);
      setAuth(false);
    }
  };

  // const refreshTokenInterval = async () => {
  //   const token = localStorage.getItem('auth.infrastruktur.token');

  //   if (token != null) {
  //     const decode = jwtDecode(token);

  //     const exp = decode.exp;
  //     const countDownDate = new Date(exp * 1000);
  //     console.log('exp', countDownDate);
  //     // let now = new Date().getTime();
  //     // const distance = countDownDate - now - 1000 * 60 * 2;

  //     const interval = 1000 * 25;
  //     intervalFn = setInterval(() => {
  //       let now = new Date().getTime();
  //       const distance = countDownDate - now - 1000 * 60 * 2;

  //       if (distance < 0) {
  //         console.log('fresh token');
  //         clearInterval(intervalFn);
  //         refreshToken();
  //       }
  //     }, interval);
  //   }
  // };

  const refreshTokenTimeOut = () => {
    const token = localStorage.getItem('auth.infrastruktur.token');

    if (token != null) {
      const decode = jwtDecode(token);

      const exp = decode.exp;
      const countDownDate = new Date(exp * 1000);
      console.log('exp', countDownDate);
      let now = new Date().getTime();
      const distance = countDownDate - now - 1000 * 60 * 2;

      timeOutFn = setTimeout(() => {
        console.log('fresh token');
        clearTimeout(timeOutFn);
        refreshToken();
      }, distance);
    }
  };

  return (
    <AuthContext.Provider value={{ auth, user, login, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProvider };
