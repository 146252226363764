import axios, { setAuthorizationHeader } from '../config/axios';

class AuthService {
  async login(credentials) {
    try {
      const res = await axios.post('/api/user/login', credentials);
      setAuthorizationHeader(res.data.data.access_token);
      localStorage.setItem(
        'auth.infrastruktur.token',
        res.data.data.access_token
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  logout() {
    try {
      localStorage.removeItem('auth.infrastruktur.token');
      setAuthorizationHeader(null);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async me() {
    try {
      const res = await axios.get('/api/user/me');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async freshToken() {
    try {
      const res = await axios.put('/api/user/fresh-token');
      setAuthorizationHeader(res.data.data.access_token);
      localStorage.setItem(
        'auth.infrastruktur.token',
        res.data.data.access_token
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default new AuthService();
