import { useNProgress } from '@tanem/react-nprogress';
import Bar from '../atom/Bar';
import ProgressBarContainer from './ProgressBarContainer';

function Progress({ isAnimating }) {
  // const { isLoading } = useContext(HomeContext);
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  });

  return (
    <ProgressBarContainer
      animationDuration={animationDuration}
      isFinished={isFinished}
    >
      <Bar animationDuration={animationDuration} progress={progress} />
    </ProgressBarContainer>
  );
}

export default Progress;
