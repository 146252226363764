import { useContext } from 'react';
import logo from '../../assets/kemenpu.jpg';
import { HomeContext } from '../../context/HomeContext';
import SidebarHomeButton from '../atom/SidebarHomeButton';

function SidebarHome() {
  const context = useContext(HomeContext);
  return (
    <div className="bg-blue-900 h-full w-56 2xl:w-80 flex flex-col fixed">
      <div className="mx-6 my-6 flex justify-center">
        <img src={logo} alt="" className="w-20 h-20 rounded-md shadow-lg" />
      </div>
      <div className="w-full pl-6 mt-3">
        <div className="font-semibold text-white mb-3">Layer</div>
        <SidebarHomeButton>
          <input
            onChange={() => context.handleOnSetDas(true)}
            id="das"
            type="checkbox"
            className="mr-6 rounded"
          />{' '}
          <label htmlFor="das">DAS</label>
        </SidebarHomeButton>
        <SidebarHomeButton>
          <input
            onChange={() => context.handleOnSetKategori(2)}
            id="bendungan"
            type="checkbox"
            className="mr-6 rounded"
          />{' '}
          <label htmlFor="bendungan">Bendungan</label>
        </SidebarHomeButton>
        <SidebarHomeButton>
          <input
            onChange={() => context.handleOnSetKategori(3)}
            id="embung"
            type="checkbox"
            className="mr-6 rounded"
          />{' '}
          <label htmlFor="embung">Embung</label>
        </SidebarHomeButton>
        <SidebarHomeButton>
          <input
            onChange={() => context.handleOnSetKategori(4)}
            id="bendung"
            type="checkbox"
            className="mr-6 rounded"
          />{' '}
          <label htmlFor="bendung">Bendung</label>
        </SidebarHomeButton>
        <SidebarHomeButton>
          <input
            onChange={() => context.handleOnSetKategori(5)}
            id="mata-air"
            type="checkbox"
            className="mr-6 rounded"
          />{' '}
          <label htmlFor="mata-air">Mata Air</label>
        </SidebarHomeButton>
        <SidebarHomeButton>
          <input
            onChange={() => context.handleOnSetKategori(6)}
            id="air-tanah"
            type="checkbox"
            className="mr-6 rounded"
          />{' '}
          <label htmlFor="air-tanah">Air Tanah</label>
        </SidebarHomeButton>
      </div>
    </div>
  );
}

export default SidebarHome;
