/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { MapContainerContext } from '../../context/MapContainerContext';
import PhotoSlider from './PhotoSlider';

export default function SliderOverDetail() {
  const { toogleSliderOver, setToogleSliderOver, infrastruktur } =
    useContext(MapContainerContext);

  useEffect(() => {
    console.log('infra slider', infrastruktur);
  }, [infrastruktur]);

  return (
    <Transition.Root show={toogleSliderOver} as={Fragment}>
      <Dialog
        as="div"
        className="fixed overflow-hidden z-10"
        onClose={() => {}}
      >
        <div className="absolute inset-0 overflow-hidden">
          {/* <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-white bg-opacity-75 transition-opacity" />
          </Transition.Child> */}
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen  max-w-lg 2xl:max-w-3xl">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-16 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md text-gray-50 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setToogleSliderOver(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <Dialog.Title className="text-lg font-medium text-white">
                      Panel title
                    </Dialog.Title>
                  </div>
                  <div className="mt-8 relative flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                    <div className="absolute inset-0 px-4 sm:px-6 mb-6">
                      {infrastruktur && (
                        // infrastruktur.photo.map((p, i) => {
                        //   return (
                        //     <div key={i}>
                        //       <img
                        //         className="w-full h-auto rounded-md"
                        //         src={axios.defaults.baseURL + p.path}
                        //         alt=""
                        //       />
                        //     </div>
                        //   );
                        // })}
                        <div>
                          <PhotoSlider
                            photos={infrastruktur.photo}
                            activeIndex={0}
                          />
                        </div>
                      )}

                      {infrastruktur && (
                        <div>
                          <h1 className="font-semibold text-2xl mt-6">
                            {infrastruktur.nama_infrastruktur}
                          </h1>
                          <h2 className="font-semibold text-base mt-6">
                            Informasi Umum
                          </h2>
                          <div className="border rounded-md mt-3">
                            <table className="w-full">
                              <tbody className="divide-y">
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    DAS
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.das}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Tahun
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.tahun}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Kondisi
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.kondisi}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Volume saat ini
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.volume_saat_ini}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Biaya Konstruksi
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.biaya_konstruksi}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Permasalahan
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.permasalahan}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Keterangan
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.keterangan}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <h2 className="font-semibold text-base mt-6">
                            Lokasi
                          </h2>

                          <div className="border rounded-md mt-3">
                            <table className="w-full">
                              <tbody className="divide-y">
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Kabupaten
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {JSON.parse(infrastruktur.kabupaten).name}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Kecamatan
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {JSON.parse(infrastruktur.kecamatan).name}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Desa
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {JSON.parse(infrastruktur.desa).name}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <h2 className="font-semibold text-base mt-6">
                            Data Teknis
                          </h2>

                          <div className="border rounded-md mt-3">
                            <table className="w-full">
                              <tbody className="divide-y">
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Catch Area
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.catch_area}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Tipe konstruksi
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.tipe_konstruksi}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Luas genangan
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.luas_genangan} m<sup>2</sup>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Volume desain tampung
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.volume_desain_tampung} m
                                    <sup>3</sup>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Volume tampung
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.volume_tampung} m<sup>3</sup>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Panjang
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.panjang} m
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Lebar
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.lebar} m
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Tinggi
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.tinggi} m
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <h2 className="font-semibold text-base mt-6">
                            Fungsi
                          </h2>

                          <div className="border rounded-md mt-3">
                            <table className="w-full">
                              <tbody className="divide-y">
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Irigasi
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.irigasi} Ha
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Ternak
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.ternak} Ekor
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Air Baku
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.air_baku} KK atau lt/dt
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    PLTA
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.plta} KVA
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-medium text-sm px-4 py-2">
                                    Lainnya
                                  </td>
                                  <td className="font-medium text-sm px-4 py-2 text-gray-500">
                                    {infrastruktur.lainnya}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                      {/* <div
                        className="h-full border-2 border-dashed border-gray-200"
                        aria-hidden="true"
                      ></div> */}
                      <div className="w-full h-6"></div>
                    </div>

                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
