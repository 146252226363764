/* eslint-disable react-hooks/exhaustive-deps */
/* This example requires Tailwind CSS v2.0+ */
import {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import UplaodField from '../atom/UploadField';
import { InfrastrukturContext } from '../../context/InfrastrukturContext';
import ThumbPhoto from '../atom/ThumbPhoto';

function ModalHistory(props, ref) {
  const { handleOnChangeHistory } = useContext(InfrastrukturContext);
  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState({
    tahun: null,
    kegiatan: '',
    anggaran: '',
    data: '',
    manfaat: '',
    photo_history: [],
  });

  // const [fields, setFields] = useState(props.fieldData);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    // console.log('fields', fields);
    setFields(props.fieldData);
  }, [open]);

  const fieldHandler = (e) => {
    const name = e.target.getAttribute('name');
    let value = e.target.value;
    if (name === 'tahun') {
      value = parseInt(value);
    }
    setFields({
      ...fields,
      [name]: value,
    });
  };

  const handleOnChangePhoto = (photo) => {
    console.log('parent', photo);
    setFields({
      ...fields,
      photo_history: [...fields.photo_history, ...photo],
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    // props.onSubmit(fields);
    handleOnChangeHistory(fields);
    props.closeModal(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  useImperativeHandle(ref, () => {
    return {
      openModal: openModal,
    };
  });

  return (
    <form onSubmit={handleOnSubmit}>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-30 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <form
                onSubmit={(e) => handleOnSubmit(e)}
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      {/* <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900 w-full"
                      >
                        Tambah History
                      </Dialog.Title>
                      <div className="mt-4 w-full">
                        {/* <ModalFormHistory /> */}

                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6">
                            <label
                              htmlFor="tahun"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Tahun
                            </label>
                            <input
                              id="tahun"
                              onKeyUp={(e) => fieldHandler(e)}
                              type="text"
                              name="tahun"
                              autoComplete="tahun"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              defaultValue={fields.tahun}
                            />
                          </div>

                          <div className="col-span-6">
                            <label
                              htmlFor="kegiatan"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Kegiatan
                            </label>
                            <textarea
                              id="kegiatan"
                              onKeyUp={(e) => fieldHandler(e)}
                              type="text"
                              name="kegiatan"
                              autoComplete="kegiatan"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              defaultValue={fields.kegiatan}
                            ></textarea>
                          </div>

                          <div className="col-span-6">
                            <label
                              htmlFor="anggaran"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Anggaran
                            </label>
                            <input
                              id="anggaran"
                              onKeyUp={(e) => fieldHandler(e)}
                              type="text"
                              name="anggaran"
                              autoComplete="anggaran"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              defaultValue={fields.anggaran}
                            />
                          </div>

                          <div className="col-span-6">
                            <label
                              htmlFor="kegiatan"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Data
                            </label>
                            <textarea
                              id="data"
                              onKeyUp={(e) => fieldHandler(e)}
                              type="text"
                              name="data"
                              autoComplete="data"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              defaultValue={fields.data}
                            ></textarea>
                          </div>
                          <div className="col-span-6">
                            <label
                              htmlFor="kegiatan"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Manfaat
                            </label>
                            <textarea
                              id="manfaat"
                              onKeyUp={(e) => fieldHandler(e)}
                              type="text"
                              name="manfaat"
                              autoComplete="manfaat"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              defaultValue={fields.manfaat}
                            ></textarea>
                          </div>
                          <div className="col-span-6">
                            <label className="block text-sm font-medium text-gray-700">
                              Photo History
                            </label>

                            <UplaodField onChange={handleOnChangePhoto} />
                            <div className="grid grid-cols-3 gap-3 mt-3">
                              {fields.photo_history.map((p, index) => {
                                return (
                                  <ThumbPhoto key={index} photo={p.path} />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    // onClick={() => props.closeModal(false)}
                  >
                    Simpan
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </form>
  );
}

export default forwardRef(ModalHistory);
