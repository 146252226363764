/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Card from '../components/atom/Card';
import PageHeader from '../components/molecul/PageHeader';

function Dashboard() {
  return (
    <div className="w-full h-full">
      <PageHeader title="Dashboard" />
      <div className="w-full h-full pt-32 p-6">
        <div className="grid grid-cols-3 gap-6">
          <div className="">
            <Card title="DAS" />
          </div>
          <div className="">
            <Card title="Bendungan" />
          </div>
          <div className="">
            <Card title="Embung" />
          </div>
          <div className="">
            <Card title="Bendung" />
          </div>
          <div className="">
            <Card title="Mata Air" />
          </div>
          <div className="">
            <Card title="Air Tanah" />
          </div>
          <div className="">
            <Card title="Hidrologi" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
