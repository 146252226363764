import { MapContainerContextProvider } from '../../context/MapContainerContext';
import MapViewB from '../atom/MapViewB';
import SliderOverDetail from './SliderOverDetail';

const MapContainerB = (props) => {
  return (
    <MapContainerContextProvider>
      <div className="w-full h-full relative">
        <MapViewB
          infraData={props.infraData}
          dasLombok={props.dasLombok}
          dasSumbawa={props.dasSumbawa}
        />
      </div>
      <SliderOverDetail />
    </MapContainerContextProvider>
  );
};

export default MapContainerB;
