import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function SidebarButton(props) {
  let location = useLocation();

  return (
    <Link
      to={props.to}
      className={`text-white text-sm rounded-l-lg w-full px-4 py-3 flex my-1 transition-all duration-100 ${
        props.to === location.pathname ? 'bg-blue-800' : 'bg-transparent'
      }`}
    >
      {props.children}
    </Link>
  );
}

export default SidebarButton;
