import { useState } from 'react';
import axios from '../../config/axios';

function PhotoSlider({ photos, activeIndex }) {
  const [active, setActive] = useState(activeIndex);

  const nextPhoto = () => {
    if (active === photos.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  };

  const prevPhoto = () => {
    if (active === 0) {
      setActive(photos.length - 1);
    } else {
      setActive(active - 1);
    }
  };

  return (
    <>
      <div>
        <div className="relative w-full aspect-w-4 aspect-h-3">
          {photos &&
            photos.map((data, index) => {
              return (
                <div
                  key={index}
                  className={`absolute w-full aspect-w-4 aspect-h-3 left-0 top-0 transition-all duration-500 rounded-md overflow-hidden ${
                    index === active ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <img
                    src={axios.defaults.baseURL + data.path}
                    alt=""
                    className="h-full w-full object-cover rounded-md"
                  />
                </div>
              );
            })}

          <div className="absolute w-full h-full left-0 top-0">
            <div className="group h-full w-full flex items-center justify-between px-1.5">
              <div
                onClick={() => prevPhoto()}
                className="ml-0 z-20 cursor-pointer opacity-20 group-hover:opacity-100 group-hover:-ml-20 transition-all duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
                  />
                </svg>
              </div>
              <div
                onClick={() => nextPhoto()}
                className="right-0 z-20 cursor-pointer opacity-20 group-hover:opacity-100 hover:opacity-100 transition-all duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhotoSlider;
