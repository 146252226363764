import React, { useContext, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

function AdminButton() {
  const { logout, user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="relative">
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <button
            className="bg-white rounded-full shadow-lg flex items-center justify-center outline-none p-1.5"
            onClick={() => setOpen(!open)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-blue-900 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clipRule="evenodd"
              />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-900"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </ClickAwayListener>

        <div
          className={`absolute bg-white rounded-lg mt-2 shadow-lg right-0 w-40 transition-all duration-300 ${
            open
              ? 'opacity-100 origin-top-right transform-gpu scale-100'
              : 'opacity-0 origin-top-right transform-gpu scale-0'
          }`}
        >
          {user ? (
            <>
              <div className="text-xs px-4 py-2 cursor-pointer bg-gray-200 rounded-md m-1 font-bold uppercase text-center text-blue-900">
                {user.name}
              </div>
              <div className="text-xs px-4 py-2 cursor-pointer hover:bg-blue-700 hover:text-white rounded-md m-1">
                Setting
              </div>
              <div
                onClick={() => logout()}
                className="text-xs px-4 py-2 cursor-pointer hover:bg-blue-700 hover:text-white rounded-md m-1"
              >
                Logout
              </div>
            </>
          ) : (
            <Link
              to="/login"
              className="flex text-xs px-4 py-2 cursor-pointer hover:bg-blue-700 hover:text-white rounded-md m-1"
            >
              Login
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminButton;
