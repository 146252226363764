import React from 'react';

function SearchBarHeader({ _onKeyUp }) {
  return (
    <>
      <div className="relative flex items-center p-1.5">
        <div className="absolute">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 ml-1.5 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <input
          onKeyUp={(event) => _onKeyUp(event)}
          className="w-80 p-1.5 pl-10 rounded-full shadow-lg outline-none"
        />
      </div>
    </>
  );
}

export default SearchBarHeader;
