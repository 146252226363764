import { useContext } from 'react';
import { HomeContext } from '../../context/HomeContext';
import Progress from './Progress';

function ProgressContainer() {
  const context = useContext(HomeContext);
  return <Progress isAnimating={context.isLoading} />;
}

export default ProgressContainer;
