import { createContext, useEffect, useState } from 'react';

export const MapContainerContext = createContext();

const MapContainerContextProvider = ({ children }) => {
  const [toogleSliderOver, setToogleSliderOver] = useState(false);
  const [infrastruktur, setInfrastruktur] = useState(null);

  useEffect(() => {
    console.log('infras', infrastruktur);
  }, [infrastruktur]);

  return (
    <MapContainerContext.Provider
      value={{
        toogleSliderOver,
        setToogleSliderOver,
        infrastruktur,
        setInfrastruktur,
      }}
    >
      {children}
    </MapContainerContext.Provider>
  );
};

export { MapContainerContextProvider };
