import axios from '../../config/axios';
import React from 'react';

function ThumbPhoto(props) {
  return (
    <>
      <div className="relative aspect-w-4 aspect-h-3">
        <img
          src={axios.defaults.baseURL + props.photo}
          alt=""
          className="rounded-md object-cover"
        />
        <div className="absolute rounded-md w-full h-full bg-black bg-opacity-50 top-0 left-0 opacity-0 hover:opacity-100 transition-opacity duration-200">
          <div className="w-full h-full flex justify-center items-center">
            <div className="flex divide-x">
              <div
                className="cursor-pointer px-3"
                onClick={props.onClickPreview}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>

              <div className="cursor-pointer px-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-white "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThumbPhoto;
