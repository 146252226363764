import axios from '../config/axios';

class InfrastrukturServices {
  async index() {
    try {
      const res = await axios.post('/api/infrastruktur');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async store(data) {
    try {
      const res = await axios.post('/api/infrastruktur', data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async show(id) {
    try {
      const res = await axios.get(`/api/infrastruktur/${id}`);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async update(data) {
    try {
      const res = await axios.put(`/api/infrastruktur`, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async destroy(id) {
    try {
      const res = await axios.delete(`/api/infrastruktur/${id}`);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default new InfrastrukturServices();
