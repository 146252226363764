/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { InfrastrukturContext } from '../../context/InfrastrukturContext';
import { AuthContext } from '../../context/AuthContext';
import infrastrukturServices from '../../services/infrastruktur-services';
import FormDataTeknis from './FormDataTeknis';
import FormFungsi from './FormFungsi';
import FormGeneral from './FormGeneral';
import FormHistory from './FormHistory';
import FormLokasi from './FormLokasi';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function FormInfra() {
  const { user } = useContext(AuthContext);
  const {
    activeTab,
    setInfraData,
    setAction,
    handleOnSetActiveTab,
    handleOnResetInfraData,
    handleOnSetKategoriId,
  } = useContext(InfrastrukturContext);
  let { id } = useParams();
  const query = useQuery();

  useEffect(() => {
    console.log('id', id);
    console.log('query', query.get('action'));
    handleOnResetInfraData();
    handleOnSetKategoriId(id);
    fethDetailInfrastruktur();
    setAction(query.get('action'));
    if (user.roles === '2') {
      handleOnSetActiveTab(5);
    }
  }, [id]);

  const fethDetailInfrastruktur = async () => {
    if (query.get('action') === 'edit') {
      const res = await infrastrukturServices.show(id);
      console.log('detail', res.data.data);
      if (res.data.data.infrastruktur) {
        setInfraData(res.data.data.infrastruktur);
      }
    }
  };

  return (
    <div className="mx-auto max-w-6xl">
      <div className="w-full h-full pt-24 p-6 relative">
        <div className="flex z-0">
          {/* {user.roles !== '2' && ( */}
          <button
            onClick={() => handleOnSetActiveTab(1)}
            className={`${
              activeTab === 1
                ? 'bg-white text-gray-800'
                : 'bg-gray-200 text-gray-500'
            } px-5 shadow-md rounded-t-md pt-3 pb-4 text-sm font-semibold mr-1 outline-none`}
          >
            General
          </button>
          {/* )} */}
          {/* {user.roles !== '2' && ( */}
          <button
            onClick={() => handleOnSetActiveTab(2)}
            className={`${
              activeTab === 2
                ? 'bg-white text-gray-800'
                : 'bg-gray-200 text-gray-500'
            } px-5 shadow-md rounded-t-md pt-3 pb-4 text-sm font-semibold mr-1 outline-none`}
          >
            Lokasi
          </button>
          {/* )} */}
          {/* {user.roles !== '2' && ( */}
          <button
            onClick={() => handleOnSetActiveTab(3)}
            className={`${
              activeTab === 3
                ? 'bg-white text-gray-800'
                : 'bg-gray-200 text-gray-500'
            } px-5 shadow-md rounded-t-md pt-3 pb-4 text-sm font-semibold mr-1 outline-none`}
          >
            Data Teknis
          </button>
          {/* )} */}
          {/* {user.roles !== '2' && ( */}
          <button
            onClick={() => handleOnSetActiveTab(4)}
            className={`${
              activeTab === 4
                ? 'bg-white text-gray-800'
                : 'bg-gray-200 text-gray-500'
            } px-5 shadow-md rounded-t-md pt-3 pb-4 text-sm font-semibold mr-1 outline-none`}
          >
            Fungsi
          </button>
          {/* )} */}
          <button
            onClick={() => handleOnSetActiveTab(5)}
            className={`${
              activeTab === 5
                ? 'bg-white text-gray-800'
                : 'bg-gray-200 text-gray-500'
            } px-5 shadow-md rounded-t-md pt-3 pb-4 text-sm font-semibold mr-1 outline-none`}
          >
            History
          </button>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-md z-10 relative -top-1.5">
          {activeTab === 1 && <FormGeneral />}
          {activeTab === 2 && <FormLokasi />}
          {activeTab === 3 && <FormDataTeknis />}
          {activeTab === 4 && <FormFungsi />}
          {activeTab === 5 && <FormHistory />}
        </div>
      </div>
    </div>
  );
}

export default FormInfra;
