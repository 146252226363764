import React from 'react';
import FormInfra from '../components/molecul/FormInfra';
import PageHeader from '../components/molecul/PageHeader';
import { InfrastrukturProvider } from '../context/InfrastrukturContext';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Infrastruktur() {
  const query = useQuery();

  return (
    <InfrastrukturProvider>
      <div className="w-full h-full">
        <PageHeader
          title={`Tambah Infrastruktur ${
            query.get('name') && query.get('name')
          }`}
        />
        <FormInfra />
      </div>
    </InfrastrukturProvider>
  );
}

export default Infrastruktur;
