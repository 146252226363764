/* eslint-disable react-hooks/exhaustive-deps */
// import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import InfrastrukturServices from '../services/infrastruktur-services';

export const InfrastrukturContext = createContext();

const initInfraData = {
  kategori_id: null,
  photo: [],
  nama_balai: 'BWS NT 1',
  nama_infrastruktur: '',
  das: '',
  tahun: 0,
  kondisi: '',
  volume_saat_ini: '',
  biaya_konstruksi: '',
  pemasalahan: '',
  keterangan: '',
  lat: '',
  lng: '',
  desa: '',
  kecamatan: '',
  kabupaten: '',
  catch_area: '',
  tipe_konstruksi: '',
  luas_genangan: '',
  volume_desain_tampung: '',
  volume_tampung: '',
  panjang: '',
  lebar: '',
  tinggi: '',
  irigasi: '',
  ternak: '',
  air_baku: '',
  plta: '',
  lainnya: '',
  history: [],
};

function InfrastrukturProvider(props) {
  const [infraData, setInfraData] = useState(initInfraData);
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  // const [apiToken, setApiToken] = useState(null);
  const [action, setAction] = useState('');

  useEffect(() => {
    console.log('init infra context', infraData);
    // getRajaApiToken();
  }, [infraData]);

  // const getRajaApiToken = async () => {
  //   try {
  //     const res = await axios.get('https://x.rajaapi.com/poe');
  //     console.log('token', res);
  //     setApiToken(res.data.token);
  //   } catch (error) {
  //     console.error('err', error);
  //   }
  // };

  const handleOnSetKategoriId = (id) => {
    setInfraData({
      ...infraData,
      kategori_id: parseInt(id),
    });
  };

  const handleOnSetInfraData = (e) => {
    console.log(e);
    const name = e.target.getAttribute('name');
    console.log(name);
    let value = e.target.value;
    if (name === 'tahun') {
      value = parseInt(value);
    }
    setInfraData({
      ...infraData,
      [name]: value,
    });
  };

  const handleOnResetInfraData = () => {
    setInfraData(initInfraData);
  };

  const handleOnChangePhoto = (path) => {
    console.log('photo', path);
    setInfraData({
      ...infraData,
      photo: [...infraData.photo, ...path],
    });
  };

  const handleOnChangePosition = (position) => {
    const latlng = {
      lat: position.lat.toString(),
      lng: position.lng.toString(),
    };
    setInfraData({
      ...infraData,
      ...latlng,
    });
    // setInfraData({
    //   ...infraData,
    //   lokasi: {
    //     ...infraData.lokasi,
    //     ...latlng,
    //   },
    // });
  };

  const handleOnChangeDataTeknis = (e) => {
    const name = e.target.getAttribute('name');
    setInfraData({
      ...infraData,
      data_teknis: {
        ...infraData.data_teknis,
        [name]: e.target.value,
      },
    });
  };

  const handleOnChangeLokasi = (e) => {
    const name = e.target.getAttribute('name');
    setInfraData({
      ...infraData,
      lokasi: {
        ...infraData.lokasi,
        [name]: e.target.value,
      },
    });
  };

  const handleOnChangeFungsi = (e) => {
    const name = e.target.getAttribute('name');
    setInfraData({
      ...infraData,
      fungsi: {
        ...infraData.fungsi,
        [name]: e.target.value,
      },
    });
  };

  const handleOnChangeHistory = (data) => {
    setInfraData({
      ...infraData,
      history: [...infraData.history, data],
    });
  };

  const handleOnSetActiveTab = (data) => {
    setActiveTab(data);
  };

  const handleOnSaveData = async () => {
    setLoading(true);
    if (action === 'edit') {
      await updateData();
    } else {
      await insertData();
    }
    setLoading(false);
  };

  const insertData = async () => {
    try {
      const res = await InfrastrukturServices.store(infraData);
      setResponse(res.data.data.message);
      console.log(res);
    } catch (error) {
      setError('Something went wrong');
      console.error(error.response);
    }
  };

  const updateData = async () => {
    try {
      const res = await InfrastrukturServices.update(infraData);
      setResponse(res.data.data.message);
      console.log(res);
    } catch (error) {
      setError('Something went wrong');
      console.error(error.response);
    }
  };

  return (
    <InfrastrukturContext.Provider
      value={{
        // apiToken,
        activeTab,
        infraData,
        setInfraData,
        response,
        loading,
        error,
        action,
        setAction,
        handleOnSetKategoriId,
        handleOnSetInfraData,
        handleOnChangePhoto,
        handleOnChangeLokasi,
        handleOnChangeDataTeknis,
        handleOnChangeFungsi,
        handleOnSetActiveTab,
        handleOnChangePosition,
        handleOnChangeHistory,
        handleOnSaveData,
        handleOnResetInfraData,
      }}
    >
      {props.children}
    </InfrastrukturContext.Provider>
  );
}

export { InfrastrukturProvider };
