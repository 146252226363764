import React, { useState } from 'react';
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
  Marker,
  useMapEvents,
} from 'react-leaflet';

function LocationMarker(props) {
  const [position, setPosition] = useState(props.position);
  useMapEvents({
    click(e) {
      console.log(e.latlng);
      setPosition(e.latlng);
      props.onChangePosition(e.latlng);
      // map.locate();
    },
  });

  return position === null ? null : (
    <Marker position={position}>{/* <Popup>You are here</Popup> */}</Marker>
  );
}

function MapView(props) {
  const position = [-8.591341, 117.361826];
  const strokeStyle = {
    weight: 1,
    fillOpacity: 0.05,
  };

  // useEffect(() => {
  //   if (props.initPosition) {
  //     setPosition(props.initPosition);
  //   } else {
  //     setPosition([-8.591341, 117.361826]);
  //   }
  // }, [props.initPosition]);

  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.Name) {
      layer.bindPopup(feature.properties.Name);
    }

    layer.on('click', () => {
      console.log('click', feature.properties.Name);
    });
  };

  const handleClickMap = (e) => {
    console.log(e);
  };

  return (
    <div className="w-full relative z-10 aspect-w-4 aspect-h-3">
      <MapContainer
        center={props.initPosition == null ? position : props.initPosition}
        zoom={9}
        scrollWheelZoom={true}
        zoomControl={false}
        style={{ width: '100%', height: '100%', zIndex: 20 }}
        onClick={handleClickMap}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          // url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          url="https://api.mapbox.com/styles/v1/alwan27/cktmlpfbh15kc18s8ofjqm53u/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWx3YW4yNyIsImEiOiJja21lcm9zbWkwemI1MnVuOGd4NzBzdmdmIn0.zH0KG2I5pGNjDXbhRhZEkg"
          // url="https://api.mapbox.com/styles/v1/alwan27/cktmk42jpa3bu17n763fur65m/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWx3YW4yNyIsImEiOiJja21lcm9zbWkwemI1MnVuOGd4NzBzdmdmIn0.zH0KG2I5pGNjDXbhRhZEkg"
          // https://api.mapbox.com/styles/v1/alwan27/cktmk42jpa3bu17n763fur65m.html?title=view&access_token=pk.eyJ1IjoiYWx3YW4yNyIsImEiOiJja21lcm9zbWkwemI1MnVuOGd4NzBzdmdmIn0.zH0KG2I5pGNjDXbhRhZEkg&zoomwheel=true&fresh=true#7.56/37.889/-121.949
        />
        {props.dasLombok && (
          <GeoJSON
            key="key-das-lombok"
            data={props.dasLombok}
            style={strokeStyle}
            onEachFeature={onEachFeature}
          />
        )}
        {props.dasSumbawa && (
          <GeoJSON
            key="key-das-sumbawa"
            data={props.dasSumbawa}
            style={strokeStyle}
            onEachFeature={onEachFeature}
          />
        )}
        <LocationMarker
          position={props.initPosition}
          onChangePosition={(pos) => props.onChangePosition(pos)}
        />

        <ZoomControl position="bottomleft" />
      </MapContainer>
    </div>
  );
}

export default MapView;
