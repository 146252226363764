import { BrowserRouter, Switch, Route } from 'react-router-dom';
import DefaultTemplate from './components/templates/DefaultTemplate';
import ProtectedRoute from './middleware/ProtectedRoute';
import Home from './pages/Home';
import Login from './pages/Login';
import Private from './pages/Private';

function App() {
  return (
    <BrowserRouter>
      <div className="bg-gray-100 min-h-screen">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/private">
            <Private />
          </Route>

          <ProtectedRoute path="/admin">
            <DefaultTemplate />
          </ProtectedRoute>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
