import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { InfrastrukturContext } from '../../context/InfrastrukturContext';

function FormDataTeknis(props) {
  const { user } = useContext(AuthContext);
  const { infraData, handleOnSetInfraData, handleOnSetActiveTab } =
    useContext(InfrastrukturContext);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    handleOnSetActiveTab(4);
  };

  if (user.roles === '2') {
    return (
      <div className="p-6">
        <h2 className="font-semibold text-base">Data Teknis</h2>
        <div className="border rounded-md mt-3">
          <table className="w-full">
            <tbody className="divide-y">
              <tr>
                <td className="font-medium text-sm px-4 py-2">Catch Area</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.catch_area}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">
                  Tipe konstruksi
                </td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.tipe_konstruksi}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Luas genangan</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.luas_genangan} m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">
                  Volume desain tampung
                </td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.volume_desain_tampung} m<sup>3</sup>
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">
                  Volume tampung
                </td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.volume_tampung} m<sup>3</sup>
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Panjang</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.panjang} m
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Lebar</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.lebar} m
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Tinggi</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {infraData.tinggi} m
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Data Teknis Infrastruktur
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Catch area</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="catch_area"
                      autoComplete="catch_area"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.catch_area}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Tipe konstruksi
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="tipe_konstruksi"
                      autoComplete="tipe_konstruksi"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.tipe_konstruksi}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Luas genangan
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="luas_genangan"
                      autoComplete="luas_genangan"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.luas_genangan}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Volume desain tampung
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="volume_desain_tampung"
                      autoComplete="volume_desain_tampung"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.volume_desain_tampung}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Volume tampung
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="volume_tampung"
                      autoComplete="volume_tampung"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.volume_tampung}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Panjang</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="panjang"
                      autoComplete="panjang"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.panjang}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Lebar</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="lebar"
                      autoComplete="lebar"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.lebar}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Tinggi</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => handleOnSetInfraData(e)}
                      type="text"
                      name="tinggi"
                      autoComplete="tinggi"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.tinggi}
                    />
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            onClick={() => handleOnSetActiveTab(2)}
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-1"
          >
            Back
          </button>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
}

export default FormDataTeknis;
