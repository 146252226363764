import React, { useContext, useRef, useState } from 'react';
import { InfrastrukturContext } from '../../context/InfrastrukturContext';
import ThumbPhoto from '../atom/ThumbPhoto';
import ModalHistory from './ModalHistory';

function FormHistory(props) {
  const { loading, response, error, infraData, handleOnSaveData } =
    useContext(InfrastrukturContext);

  const [modalHistory, setModalHistory] = useState(false);
  const [history, setHistory] = useState([]);
  const [fieldData, setFieldData] = useState({
    id: null,
    tahun: 0,
    kegiatan: '',
    anggaran: '',
    data: '',
    manfaat: '',
    photo_history: [],
  });

  const modalHistoryRef = useRef(null);

  const openModalHistory = () => {
    modalHistoryRef.current.openModal();
  };

  const handleOnSubmit = (data) => {
    setHistory({
      ...history,
      data,
    });
  };

  const numberFormat = (n, currency = 'Rp. ') => {
    return (
      currency +
      Number(n)
        .toFixed(0)
        .replace(/./g, function (c, i, a) {
          console.log('c', c);
          console.log('i', i);
          console.log('a', a);
          return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? '.' + c : c;
        })
    );
  };

  return (
    <>
      <div>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Data Teknis Infrastruktur
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <div className="p-6">
            <button
              onClick={() => {
                openModalHistory(true);
                setFieldData({
                  id: null,
                  tahun: 0,
                  kegiatan: '',
                  anggaran: '',
                  data: '',
                  manfaat: '',
                  photo_history: [],
                });
              }}
              className="text-sm bg-gray-200 rounded-md px-4 py-2 hover:bg-gray-300 outline-none"
            >
              + Tambah History
            </button>

            <div className="w-full border rounded-md overflow-hidden mt-6">
              <table className="w-full divide-y">
                <thead className="divide-y">
                  <tr>
                    <td className="px-3 py-2 font-semibold text-sm">Tahun</td>
                    <td className="px-3 py-2 font-semibold text-sm">
                      Kegiatan
                    </td>
                    <td className="px-3 py-2 font-semibold text-sm">
                      Anggaran
                    </td>
                    <td className="px-3 py-2 font-semibold text-sm">Data</td>
                    <td className="px-3 py-2 font-semibold text-sm">Manfaat</td>
                    <td className="px-3 py-2 font-semibold text-sm">Photo</td>
                  </tr>
                </thead>
                <tbody className="divide-y">
                  {infraData.history.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td className="px-3 py-2 text-sm">{data.tahun}</td>
                        <td className="px-3 py-2 text-sm">{data.kegiatan}</td>
                        <td className="px-3 py-2 text-sm">
                          {numberFormat(data.anggaran)}
                        </td>
                        <td className="px-3 py-2 text-sm">{data.data}</td>
                        <td className="px-3 py-2 text-sm">{data.manfaat}</td>
                        <td className="px-3 py-2 text-sm">
                          {data.photo_history.map((p, i) => {
                            return (
                              <div className="w-24" key={i}>
                                <ThumbPhoto key={i} photo={p.path} />
                              </div>
                            );
                          })}
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              openModalHistory(true);
                              setFieldData(data);
                            }}
                            className="text-sm text-blue-500 font-medium px-2"
                          >
                            Edit
                          </button>
                          <button className="text-sm text-red-500 font-medium px-2">
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="text-xs mt-6">
              {response}
              {error}
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            onClick={() => handleOnSaveData()}
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {loading ? 'Loading...' : 'Simpan'}
          </button>
        </div>
      </div>
      <ModalHistory
        ref={modalHistoryRef}
        open={modalHistory}
        setOpen={() => setModalHistory}
        closeModal={() => setModalHistory(false)}
        fieldData={fieldData}
        onSubmit={handleOnSubmit}
      />
    </>
  );
}

export default FormHistory;
