/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { InfrastrukturContext } from '../../context/InfrastrukturContext';
import MapView from '../atom/MapView';

function FormLokasi() {
  const { user } = useContext(AuthContext);
  const {
    // apiToken,
    infraData,
    action,
    handleOnSetInfraData,
    handleOnChangePosition,
    handleOnSetActiveTab,
  } = useContext(InfrastrukturContext);
  const [kabupaten, setKabupaten] = useState(null);
  const [kecamatan, setKecamatan] = useState(null);
  const [desa, setDesa] = useState(null);

  useEffect(() => {
    getKabupaten();
    if (action === 'edit') {
      getKecamatan(JSON.parse(infraData.kabupaten).id);
      getDesa(JSON.parse(infraData.kecamatan).id);
    }
  }, []);

  const onChangePosition = (position) => {
    handleOnChangePosition(position);
  };

  const handleOnChangeKabupaten = (e) => {
    console.log('data', e.target.value);
    handleOnSetInfraData(e);
    getKecamatan(JSON.parse(e.target.value).id);
  };

  const handleOnChangeKecamatan = (e) => {
    handleOnSetInfraData(e);
    console.log('id kecamatan', e.target.value);
    getDesa(JSON.parse(e.target.value).id);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    handleOnSetActiveTab(3);
  };

  const getKabupaten = async () => {
    try {
      const res = await axios.get(
        'https://www.emsifa.com/api-wilayah-indonesia/api/regencies/52.json'
      );
      console.log('kabupaten', res.data);
      setKabupaten(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getKecamatan = async (id) => {
    try {
      const res = await axios.get(
        `https://www.emsifa.com/api-wilayah-indonesia/api/districts/${id}.json`
      );
      console.log('kecamatan', res.data);
      setKecamatan(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDesa = async (id) => {
    try {
      const res = await axios.get(
        `https://www.emsifa.com/api-wilayah-indonesia/api/villages/${id}.json`
      );
      setDesa(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  if (user.roles === '2') {
    return (
      <div className="p-6">
        <h2 className="font-semibold text-base">Lokasi</h2>
        <div className="border rounded-md mt-3">
          <table className="w-full">
            <tbody className="divide-y">
              <tr>
                <td className="font-medium text-sm px-4 py-2">Kabupaten</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {JSON.parse(infraData.kabupaten).name}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Kecamatan</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {JSON.parse(infraData.kecamatan).name}
                </td>
              </tr>
              <tr>
                <td className="font-medium text-sm px-4 py-2">Desa</td>
                <td className="font-medium text-sm px-4 py-2 text-gray-500">
                  {JSON.parse(infraData.desa).name}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Lokasi Infrastruktur
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Kordinat</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="rounded-md overflow-hidden">
                  <MapView
                    initPosition={
                      infraData.lat === '' && infraData.lng === ''
                        ? null
                        : {
                            lat: infraData.lat,
                            lng: infraData.lng,
                          }
                    }
                    onChangePosition={(position) => onChangePosition(position)}
                  />
                </div>
                <div className="grid grid-cols-6 gap-6 mt-3">
                  <div className="col-span-3">
                    <input
                      onChange={(e) => {
                        // if (user.roles !== '2') {
                        handleOnSetInfraData(e);
                        // }
                      }}
                      type="text"
                      name="lat"
                      autoComplete="latitude"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      placeholder="Latitude"
                      value={infraData.lat}
                    />
                  </div>
                  <div className="col-span-3">
                    <input
                      onChange={(e) => {
                        // if (user.roles !== '2') {
                        handleOnSetInfraData(e);
                        // }
                      }}
                      type="text"
                      name="lng"
                      autoComplete="longitude"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      placeholder="Longitude"
                      value={infraData.lng}
                    />
                  </div>
                </div>
              </dd>
            </div>

            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Kabupaten</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-4">
                    <select
                      onChange={(e) => handleOnChangeKabupaten(e)}
                      name="kabupaten"
                      autoComplete="kabupaten"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.kabupaten}
                    >
                      <option>Kabupaten</option>
                      {kabupaten &&
                        kabupaten.map((data, i) => {
                          return (
                            <option key={i} value={JSON.stringify(data)}>
                              {data.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </dd>
            </div>

            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Kecamatan</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-4">
                    <select
                      onChange={(e) => handleOnChangeKecamatan(e)}
                      name="kecamatan"
                      autoComplete="kecamatan"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.kecamatan}
                    >
                      <option>Kecamatan</option>
                      {kecamatan &&
                        kecamatan.map((data, i) => {
                          return (
                            <option key={i} value={JSON.stringify(data)}>
                              {data.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </dd>
            </div>

            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Desa</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-4">
                    <select
                      onChange={(e) => handleOnSetInfraData(e)}
                      name="desa"
                      autoComplete="desa"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={infraData.desa}
                    >
                      <option>Desa</option>
                      {desa &&
                        desa.map((data, i) => {
                          return (
                            <option key={i} value={JSON.stringify(data)}>
                              {data.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            onClick={() => handleOnSetActiveTab(1)}
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-1"
          >
            Back
          </button>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
}

export default FormLokasi;
