import React from 'react';
import PageHeader from '../components/molecul/PageHeader';
import SidebarHome from '../components/molecul/SidebarHome';
import { HomeContextProvider } from '../context/HomeContext';
import HomeMapContainer from '../components/molecul/HomeMapContainer';
import HomeSearchbar from '../components/molecul/HomeSearchBar';
import ProgressContainer from '../components/molecul/ProgressContainer';

function Home() {
  return (
    <>
      <HomeContextProvider>
        <ProgressContainer />
        <div className="w-full min-h-screen flex">
          <div className="w-56 2xl:w-80 h-screen relative">
            <SidebarHome />
          </div>
          <div className="flex-1 w-full min-h-screen">
            <PageHeader title="Home">
              <div className="flex items-center mr-6">
                <HomeSearchbar />
              </div>
            </PageHeader>
            <HomeMapContainer />
          </div>
        </div>
      </HomeContextProvider>
    </>
  );
}

export default Home;
