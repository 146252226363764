import React from 'react';

function BackButton(props) {
  return (
    <button
      className="h-8 w-8 bg-white rounded-lg shadow-lg flex items-center justify-center outline-none"
      onClick={() => window.history.back()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 text-blue-900"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
}

export default BackButton;
