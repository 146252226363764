import { useEffect, useState } from 'react';
import axios from '../config/axios';

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setData(null);
    setError(null);
    // const source = axios.CancelToken.source();
    axios
      .get(url)
      .then((res) => {
        setLoading(false);
        //checking for multiple responses for more flexibility
        //with the url we send in.
        console.log('use fetch  data', res);
        res.data.data && setData(res.data.data);
        // res.content && setData(res.content);
      })
      .catch((err) => {
        setLoading(false);
        setError('An error occurred. Awkward..');
      });
    // return () => {
    //   source.cancel();
    // };
  }, [url]);

  return {
    data,
    loading,
    error,
  };
};

export default useFetch;
