import L from 'leaflet';
import MarkerRed from '../../assets/marker-red.svg';
import MarkerOrange from '../../assets/marker-orange.svg';
import MarkerGreen from '../../assets/marker-green.svg';
import MarkerSky from '../../assets/marker-sky.svg';

function MarkerCustom(_iconSize, kategori) {
  console.log('kategori', kategori);
  let icon = null;

  if (kategori === 2) {
    icon = MarkerSky;
  } else if (kategori === 3) {
    icon = MarkerOrange;
  } else if (kategori === 4) {
    icon = MarkerGreen;
  } else if (kategori === 5) {
    icon = MarkerRed;
  }

  return new L.Icon({
    iconUrl: icon,
    iconAnchor: [16, 32],
    popupAnchor: [0, -30],
    iconSize: [_iconSize, _iconSize],
  });
}

export default MarkerCustom;
