import axios from '../config/axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchBarHeader from '../components/atom/SearchBarHeader';
import MapContainerB from '../components/molecul/MapContainerB';
import PageHeader from '../components/molecul/PageHeader';

function Bendungan() {
  // const { data } = useFetch('/api/infrastruktur?kategori=2');
  const kategori = 2;
  const [data, setData] = useState(null);

  useEffect(() => {
    getDataInfrastruktu();
  }, []);

  const getDataInfrastruktu = async () => {
    try {
      const res = await axios.get(`/api/infrastruktur?kategori=${kategori}`);
      console.log(res);
      setData(res.data.data.infrastruktur);
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <div className="w-full relative">
      <PageHeader title="Bendungan">
        <div className="flex items-center mr-6">
          <SearchBarHeader />
          <Link to={`/admin/infrastruktur/2?action=add&name=bendungan`}>
            <button className="p-1.5 shadow-lg rounded-full flex items-center justify-center bg-white outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-blue-900"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </button>
          </Link>
        </div>
      </PageHeader>
      {<MapContainerB infraData={data} />}
    </div>
  );
}

export default Bendungan;
