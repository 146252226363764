import { useContext } from 'react';
import { HomeContext } from '../../context/HomeContext';
import MapContainerB from './MapContainerB';

function HomeMapContainer() {
  const context = useContext(HomeContext);
  return (
    <MapContainerB
      dasLombok={context.dasLombok}
      dasSumbawa={context.dasSumbawa}
      infraData={context.infraData}
    />
  );
}

export default HomeMapContainer;
